import axios from 'axios';
import { Product } from 'src/models/product.model';
import { OrderLine } from 'src/models/order-line.model';
import { formatPrice } from 'src/utilities/helpers';
// BESPOKE 
import { isRepresentativeProduct } from '../../services/product-service';
// END BESPOKE

const state = {
  products: [],
  orderLines: [],
  productCodes: [],
  totalProductsCount: 0,
  shoppingCartInitizalized: false,
  loadingOrderLines: false,
  validatingStock: false,
  shoppingCartTotals: {
    CouponsTax: { price: '€ 0,00', rawPrice: 0 },
    CouponsNet: { price: '€ 0,00', rawPrice: 0 },
    CouponsTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTax: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsNet: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTotal: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsTax: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsNet: { price: '€ 0,00', rawPrice: 0 },
    ShippingCosts: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalTax: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalNet: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderTotal: { price: '€ 0,00', rawPrice: 0 },
    // BESPOKE
    OrderVatTotal: { price: '€ 0,00', rawPrice: 0 },
    // END BESPOKE
  },
  shoppingCartValid: false,
  shoppingCartEmpty: false,
  minimumOrderQuantityPrice: '€ 0,00',
  minimumOrderQuantityRawPrice: 0,
  orderLinesTotalPrice: '€ 0, 00',
  orderLinesTotalRawPrice: 0,

  invalidOrderLines: [],
  totalOrderLines: 0,
  stockValid: false,
  couponCode: '',
  hasCoupon: false,
  shoppingCartTotalsFormat: [],
  shoppingCartControlTotalsFormat: [],
  // BESPOKE VLINT
  showAddedToCartMessage: false,
  projectOrderLines: {
    code: "",
    description: "",
    locations: []
  }
  // END BESPOKE VLINT
};

const getters = {
  productCodes (state) {
    return state.productCodes;
  },
  orderLines (state) {
    return state.orderLines;
  },
  totalProductsCount (state) {
    return state.totalProductsCount;
  },
  loadingOrderLines (state) {
    return state.loadingOrderLines;
  },
  validatingStock (state) {
    return state.validatingStock;
  },
  shoppingCartInitizalized (state) {
    return state.shoppingCartInitizalized;
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;
    return `${endpoint}/${client}?language=${language}`;
  },
  shoppingCartTotals (state) {
    return state.shoppingCartTotals;
  },
  shoppingCartTotalsFormat (state) {
    return state.shoppingCartTotalsFormat;
  },
  shoppingCartControlTotalsFormat (state) {
    return state.shoppingCartControlTotalsFormat;
  },
  stockValid (state, getters, rootState, rootGetters) {
    if (!rootGetters.stockLimit) {
      return true;
    } else {
      return state.stockValid;
    }
  },
  invalidOrderLines (state) {
    return state.invalidOrderLines;
  },
  shoppingCartEmpty (state) {
    return state.shoppingCartEmpty;
  },
  orderLinesTotal (state) {
    return state.shoppingCartTotals.OrderLinesTotal;
  },
  minimumOrderQuantity (state) {
    return state.minimumOrderQuantity;
  },
  minimumOrderQuantityPrice (state) {
    return state.minimumOrderQuantityPrice;
  },
  minimumOrderQuantityRawPrice (state) {
    return state.minimumOrderQuantityRawPrice;
  },
  orderLinesTotalPrice (state) {
    return state.orderLinesTotalPrice;
  },
  orderLinesTotalRawPrice (state) {
    return state.orderLinesTotalRawPrice;
  },
  minimumOrderQuantityValid (state, getters, rootState, rootGetters) {
    if (rootGetters.useMinimumOrderQuantity) {
      return getters.orderLinesTotalRawPrice - getters.minimumOrderQuantityRawPrice > 0;
    } else {
      return true;
    }
  },
  couponCode (state) {
    return state.couponCode;
  },
  hasCoupon (state) {
    return state.hasCoupon;
  },
  showCheckoutButton (state, getters) {
    return getters.stockValid && getters.minimumOrderQuantityValid;
  },
  // BESPOKE VLINT
  showAddedToCartMessage(state) {
    return state.showAddedToCartMessage;
  },
  projectOrderLines(state) {
    return state.projectOrderLines;
  }
  // END BESPOKE VLINT
};

const mutations = {
  initConfig (state, config) {
    state.shoppingCartTotalsFormat = config.shoppingCartTotalsFormat;
    state.shoppingCartControlTotalsFormat = config.shoppingCartControlTotalsFormat;
  },
  setProductCodes (state, productCodes) {
    state.productCodes = productCodes;
  },
  setProducts (state, products) {
    const orderLines = [...state.orderLines];
    orderLines.forEach(orderLine => {
      orderLine.setProduct(products.filter(product => product.id === orderLine.productId)[0]);
      orderLine.setComputedQuantity();
    });

    // BESPOKE VLINT
    // Also set product information for te project orderLines
    const projectOrderLocations = [...state.projectOrderLines.locations];
    projectOrderLocations.forEach(location => {
      location.orderLines.forEach(orderLine => {
        orderLine.setProduct(products.filter(product => product.id === orderLine.productId)[0]);
        orderLine.setComputedQuantity();
      });
    });
    // END BESPOKE VLINT
  },
  setOrderLines (state, orderLines) {
    state.orderLines = orderLines;
    state.totalOrderLines = orderLines.length;
  },
  setLoadingOrderLines (state, loading) {
    state.loadingOrderLines = loading;
  },
  setValidatingStock (state, validating) {
    state.validatingStock = validating;
  },
  addToCart (state, productRow) {
    state.shoppingCartRows.push(productRow);
  },
  setTotalAndCount (state, totalProducts) {
    state.totalProductsCount = totalProducts;
  },
  setShoppingCartEmpty (state, empty) {
    state.shoppingCartEmpty = empty;
  },
  setProductPrice (state, productWithPrice) {
    state.orderLines.forEach((orderLine) => {
      if (orderLine.productId === productWithPrice.id) {
        orderLine.product.prices = productWithPrice.prices;
        orderLine.setOrderLineTotal();
      }
    });

    // BESPOKE VLINT
    // Also set the computed totals for the project list orderlines
    var projectOrderLinesLocations = state.projectOrderLines.locations;
    if (projectOrderLinesLocations.length) {
      projectOrderLinesLocations.forEach(location => {
        location.orderLines.forEach(orderLine => {
          if (orderLine.productId === productWithPrice.id) {            
            orderLine.setOrderLineTotal();
          }
        })
      });
    }
    // END BESPOKE VLINT
  },
  setProductStock (state, productWithStock) {
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === productWithStock.id) {
        orderLine.product.setStock(productWithStock.stock);
        orderLine.setComputedQuantity();
      }
    });
  },
  // BESPOKE VLINT
  // Added isProjectOrderLine to be taken into account to update project list orderlines as well
  updateOrderLineQuantity (state, { lineId, quantity, unitCode, totalCount, showPrices, isProjectOrderLine }) {
    if (isProjectOrderLine) {
      let locations =  state.projectOrderLines.locations;
      let locationIndex;
      let orderLineIndex;

      locations.forEach((location, locationInd) => {
        var index =  location.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
        if (index >= 0) {
          locationIndex = locationInd;
          orderLineIndex = index;
        }
      });
      var orderLineToUpdate = locations[locationIndex].orderLines[orderLineIndex];
      orderLineToUpdate.quantity = quantity;
      orderLineToUpdate.unitCode = unitCode;
      orderLineToUpdate.totalOrderLinesQuantity = totalCount;
      
      orderLineToUpdate.setComputedQuantity();

      if (showPrices) {
        orderLineToUpdate.setOrderLineTotal();
      }

    } else {
      let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
      state.orderLines[index].quantity = quantity;
      state.orderLines[index].unitCode = unitCode;

      // update total orderline quantities
      let prodCode = state.orderLines[index].productId;
      state.orderLines.forEach(orderLine => {
        if (orderLine.productId === prodCode) {
          orderLine.totalOrderLinesQuantity = totalCount;
        }
      });
      if (showPrices) {
        state.orderLines[index].setOrderLineTotal();
      }
      state.orderLines[index].setComputedQuantity();
    }    
  },
  // END BESPOKE VLINT
  updateOrderLineComment (state, { lineId, comment, projectCode, locationCode }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    if (index > -1) {      
      state.orderLines[index].comments = comment;
    } else {
      let locationIndex =  state.projectOrderLines.locations.findIndex(projectLine => projectLine.code === locationCode);
      let orderLineIndex = state.projectOrderLines.locations[locationIndex].orderLines.findIndex(line => line.lineId === lineId);
      state.projectOrderLines.locations[locationIndex].orderLines[orderLineIndex].comments = comment;
    }
  },
  deleteOrderLine (state, { orderLineIndex, totalCount }) {
    const orderLines = [ ...state.orderLines ];
    let prodCode = state.orderLines[orderLineIndex].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    orderLines.splice(orderLineIndex, 1);
    state.orderLines = orderLines;
  },
  setShoppingCartTotals (state, totals) {
    let totalsObj = {};
    Object.keys(totals).forEach(key => {
      totalsObj[key] = { price: formatPrice(totals[key]), rawPrice: totals[key] };
    });
    // BESPOKE VLINT-62
    // Add an entry for all values combined
    var totalVat = totals.ShippingCostsTax + totals.CouponsTax + totals.OrderCostsTax + totals.OrderLinesTotalTax - totals.CouponsTax;
    totalsObj.OrderVatTotal = { price: formatPrice(totalVat), rawPrice: totalVat }
    // END BESPOKE VLINT-62
    state.shoppingCartTotals = totalsObj;
    state.minimumOrderQuantityPrice = formatPrice(totals.MinimumOrderQuantity);
    state.minimumOrderQuantityRawPrice = totals.MinimumOrderQuantity;
    state.orderLinesTotalPrice = formatPrice(totals.OrderLinesTotal);
    state.orderLinesTotalRawPrice = totals.OrderLinesTotal;
  },
  setCouponCode (state, { CouponCode, HasCoupon }) {
    state.couponCode = CouponCode;
    state.hasCoupon = HasCoupon;
  },
  setShoppingCartInitizalized (state, intitialized) {
    state.shoppingCartInitizalized = intitialized;
  },
  checkStockState (state) {
    const orderLines = [...state.orderLines];
    let invalidLines = [];
    orderLines.forEach(orderLine => {
      if (orderLine.product.customBooleans.STOCK_PRODUCT) {
        if (orderLine.product.stock.stockTotal < orderLine.totalOrderLinesQuantity) {
          invalidLines.push(orderLine);
        }
      }
    });
    if (invalidLines.length) {
      state.stockValid = false;
      state.invalidOrderLines = invalidLines;
    } else {
      state.stockValid = true;
      state.invalidOrderLines = [];
    }
  },
  emptyShoppingCart (state) {
    state.orderLines = [];

    // BESPOKE VLINT
    // Empty project orderlines as well
    state.projectOrderLines = {
      code: "",
      description: "",
      locations: []
    };
    // END BESPOKE VLINT

    state.totalProductsCount = 0;
  },
  // BESPOKE VLINT
  setShowAddedToCartMessage(state, showMessage) {
    state.showAddedToCartMessage = showMessage;
  },
  setProjectOrderLines(state, projectOrderLines) {
    state.projectOrderLines = projectOrderLines;
  },
  deleteProjectOrderLine(state, { orderLineId, totalCount, productId }) {
    const projectOrderLines = { ...state.projectOrderLines };

    // Update total quantity count for each project orderline with the product that was deleted,
    // which is used to determine if an orderline exceeds the stock limit if applicable in the case
    // there are multiple orderlines of the same product
    projectOrderLines.locations.forEach(location => {
      location.orderLines.forEach(orderLine => {
        if (orderLine.productId === productId) {
          orderLine.totalOrderLinesQuantity = totalCount;
        }
      });
    });

    // Delete the actually deleted orderline from state
    projectOrderLines.locations.every(location => {

      var orderLineIndex = location.orderLines.findIndex(orderLine => orderLine.lineId === orderLineId);
      if (orderLineIndex < 0) {
        return true;
      } else {
        location.orderLines.splice(orderLineIndex, 1);
        return false;
      }
    });

    // Clear empty locations from the projectListOrders
    let index = projectOrderLines.locations.findIndex(location => location.orderLines.length === 0);
    if (index >= 0) {
      projectOrderLines.locations.splice(index, 1);
    }

    // Clear project orderlines if all orderlines have been deleted
    if (projectOrderLines.locations.length === 0) {
      state.projectOrderLines = {
        code: "",
        description: "",
        locations: []
      };
    }
  },
  // END BESPOKE VLINT
}

const actions = {
  initShoppingCart ({ getters, commit, dispatch }) {
    dispatch('getOrderLines');
    commit('setShoppingCartInitizalized', true);
  },
  getShoppingCartTotals ({ commit, rootGetters, dispatch }) {
    axios.post(rootGetters.shoppingCartTotalsEndpoint, {})
      .then(res => {
        const data = res.data.d.Totals;
        commit('setShoppingCartTotals', data.Prices);
        dispatch('renderCheckoutButton');
        if (rootGetters.useCoupons) {
          commit('setCouponCode', data.CouponInfo);
        }
      });
  },
  getShoppingCartTotalAndCount ({ commit, rootGetters }) {
    axios.post(rootGetters.shoppingCartTotalAndCountEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          commit('setTotalAndCount', data.Totals.ProductCount);
        } else {
          // TODO ERROR HANDLING
        }
      });
  },
  getOrderLines ({ commit, dispatch, rootGetters }) {
    commit('setLoadingOrderLines', true);
    commit('setOrderLines', []);
    axios.post(rootGetters.orderLinesEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          dispatch('getShoppingCartTotals');
          if (data.Lines.length) {
            const productCodes = data.Lines.map(orderLine => orderLine.ProductId);
            commit('setShoppingCartEmpty', false);
            commit('setProductCodes', productCodes);

            // BESPOKE VLINT-13  
            let orderLines = [];
            
            let projectOrderLines = {
              code: "",
              description: "",
              locations: []
            };
            
            // Access glo
            const globalBespokeGetters = rootGetters.bespokeGlobalWs;

            data.Lines.forEach(orderLine => {
              
              var projectLocation = orderLine.CustomFields !== undefined ? orderLine.CustomFields.project : null;

              if (!projectLocation) {
                orderLines.push(orderLine);
              } else {
                let projectNameIsSet = false;
                  
                let projectCode = orderLine.CustomFields.project;
                let projectName  = orderLine.CustomFields.projectDesc;
                
                // All project related orderlines are grouped together,
                // so on initial loop set the project name
                if (!projectNameIsSet) {
                  projectOrderLines.code = projectCode;
                  projectOrderLines.description = projectName;
                  projectNameIsSet = true;
                }
                
                // Enrich all order lines with project data
                let locationCode = orderLine.CustomFields.locationCode;
                let locationDescription = orderLine.CustomFields.locationDescription;
                
                orderLine.projectName = projectName;
                orderLine.projectCode = projectCode;
                orderLine.locationCode = locationCode;
                orderLine.locationDesctiption = locationDescription;

                // If a orderline is a product which is a representative
                // product, as set in uws_settings.isrep_proj_prod. flag it as true
                orderLine.isRepresentativeProduct = isRepresentativeProduct(orderLine.ProductId);

                let locationExistsInObj = projectOrderLines.locations.some(location => location.code === locationCode);

                if (!locationExistsInObj) {
                  projectOrderLines.locations.push({
                    code: locationCode,
                    description: locationDescription,
                    orderLines: []
                  });
                }

                projectOrderLines.locations.every(location => {
                  if (location.code === locationCode) {
                    location.orderLines.push(new OrderLine(orderLine));
                    return false;
                  }
                  return true;
                });
              }
              commit('setProjectOrderLines', projectOrderLines);
            });
            // END BESPOKE VLINT

            commit('setOrderLines', orderLines.map(orderLine => new OrderLine(orderLine)));
            dispatch('getProductInformation');
          } else {
            commit('setShoppingCartEmpty', true);
            dispatch('renderCheckoutButton');
          }
        } else if (data.Status === 'Failure') {
          dispatch('renderCheckoutButton');
          window.updateErrorMessage(data.ErrorMessage);
        }
        commit('setLoadingOrderLines', false);
      });
  },
  getOrderLinesPromise ({ commit, dispatch, rootGetters }) {
    //BESPOKE
    return new Promise((resol) => {
      commit('setLoadingOrderLines', true);
      axios.post(rootGetters.orderLinesEndpoint, {})
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            dispatch('getShoppingCartTotals');
            if (data.Lines.length) {
              const productCodes = data.Lines.map(orderLine => orderLine.ProductId);
              commit('setShoppingCartEmpty', false);
              commit('setProductCodes', productCodes);
              commit('setOrderLines', data.Lines.map(orderLine => new OrderLine(orderLine)));
              dispatch('getProductInformation');
            } else {
              commit('setShoppingCartEmpty', true);
              dispatch('renderCheckoutButton');
            }
          } else if (data.Status === 'Failure') {
            dispatch('renderCheckoutButton');
            window.updateErrorMessage(data.ErrorMessage);
          }
          commit('setLoadingOrderLines', false);
          resol();
        });
    });    
  },
  getProductInformation ({ getters, rootGetters, commit, dispatch }) {
    axios.post(getters.productInformationEndpoint, getters.productCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        commit('setProducts', products);
        
        var usingShoppingCartOverview = window.vue.modules.shoppingCartOverview != undefined;
        if (rootGetters.showStock && usingShoppingCartOverview) {
          dispatch('getProductStock', products);
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }

        if (rootGetters.showPrices) {
          dispatch('getProductPrices', products);
        }
      });
  },
  validateShoppingCartStock ({ commit, getters, rootGetters, dispatch }) {
    commit('setValidatingStock', true);
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    const products = getters.orderLines.map(orderLine => orderLine.product);
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;
          product.setStock(res.data.d);
          commit('setProductStock', product);
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            if (getters.invalidOrderLines.length) {
              const message = window.vue.translations.message.message_invalid_stock_orderlines;
              const offset = window.$('#stock-warning').offset().top - 75;
              window.updateErrorMessage(message);
              window.scrollTo({ top: offset, behavior: 'smooth' });
              dispatch('renderCheckoutButton');
            } else {
              dispatch('checkout');
            }
            commit('setValidatingStock', false);
          }
        });
    });
  },
  getProductStock ({ rootGetters, getters, commit, dispatch }, products) {
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;
          product.setStock(res.data.d);
          commit('setProductStock', product);
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            dispatch('renderCheckoutButton');
          }
        });
    });
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          commit('setProductPrice', product);
        });
      });
  },
  updateOrderLineQuantity ({ rootGetters, commit, dispatch }, orderLine) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            commit('updateOrderLineQuantity', {
              lineId: data.UpdatedLine.LineId,
              quantity: parseInt(data.UpdatedLine.Quantity),
              unitCode: data.UpdatedLine.UnitCode,
              totalCount: data.UpdatedLine.TotalCount,
              showPrices: rootGetters.showPrices,
              // BESPOKE VLINT
              // Added isProjectListOrderLine
              isProjectOrderLine: orderLine.isProjectOrderLine
              // END BESPOKE VLINT
            });
            commit('setShoppingCartTotals', data.Totals.Prices);
            dispatch('getShoppingCartTotalAndCount');

            if (rootGetters.stockLimit) {
              commit('checkStockState');
            }

            dispatch('renderCheckoutButton');

            resolve(true);
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
            dispatch('getOrderLines');
          }
        });
    });
  },
  updateOrderLineComment ({ rootGetters, commit }, orderLine) {
    // BESPOKE
    // Since vlint does not seem to use the orderline comments, we do not fix
    // the comments for the project list orderlines
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            commit('updateOrderLineComment', { lineId: data.UpdatedLine.LineId, comment: data.UpdatedLine.Comments, projectCode: orderLine.projectCode, locationCode: orderLine.locationCode });
            resolve();
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
          }
        });
    });
  },
  // BESPOKE VLINT
  // Refactored deleteOrderLine to handle deleting default orderLines as well as project orderlines
  deleteOrderLine ({ rootGetters, getters, commit, dispatch }, orderLineToBeDeleted) {
    
    let isProjectOrderLine = orderLineToBeDeleted.projectName != undefined;
    let orderLineId = orderLineToBeDeleted.id;
    let orderLineIndex;
    let orderLineFromState;

    // If orderline does not belong to a project, remove the orderline from the default orderlines
    if (isProjectOrderLine) {
      var location = getters.projectOrderLines.locations.find(location => location.code === orderLineToBeDeleted.locationCode);
      orderLineFromState = location.orderLines.find(orderLine => orderLine.lineId === orderLineToBeDeleted.lineId);
    } else {
      orderLineIndex = getters.orderLines.findIndex(orderLine => orderLine.lineId === orderLineToBeDeleted.lineId);
      orderLineFromState = getters.orderLines[orderLineIndex];
    }

    const payload = {
      LineId: orderLineFromState.lineId,
      ShoppingCartId: orderLineFromState.shoppingCartId,
      ProductId: orderLineFromState.productId,
      Comments: orderLineFromState.comments,
      Quantity: orderLineFromState.quantity,
      UnitCode: orderLineFromState.unitCode,
      IsFree: orderLineFromState.isFree
    }

    axios.post(rootGetters.deleteOrderLineEndpoint, { lineToBeDeletedObj: payload }).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {

        if (!isProjectOrderLine) {
          commit('deleteOrderLine', {
            orderLineIndex: orderLineIndex,
            totalCount: data.LineToBeDeleted.TotalCount
          });
        } else {
          commit('deleteProjectOrderLine', {
            orderLineId: orderLineFromState.lineId,
            totalCount: data.LineToBeDeleted.TotalCount,
            productId: orderLineFromState.productId
          });
        };        
        
        commit('setTotalAndCount', data.TotalsCount.ProductCount);
        commit('setShoppingCartTotals', data.Totals.Prices);
        if (rootGetters.stockLimit) {
          commit('checkStockState');
          dispatch('renderCheckoutButton');
        }
        if (getters.orderLines.length === 0 && getters.projectOrderLines.locations.length === 0) {
          commit('setShoppingCartEmpty', true);
        }
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.ErrorMessage);
      }
    });
  },
  // BESPOKE VLINT-193
  // Delete multiple orderlines from a location, in practice this is used
  // when the user has a location with products the representative added
  // which cannot be deleted individually
  deleteLocationOrderLines({ rootGetters, getters, commit, dispatch }, locationCodeToBeDeleted) {
    
    return new Promise((resolveDeleteLocation) => {

      var location = getters.projectOrderLines.locations.find(location => location.code === locationCodeToBeDeleted);

      const asyncFunction = (payload) => {
        
        return new Promise((resolve, reject) => {
          axios.post(rootGetters.deleteOrderLineEndpoint, { lineToBeDeletedObj: payload }).then(res => {
            const data = res.data.d;
            if (data.Status === 'Success') {
              resolve(data);            
            } else if (data.Status === 'Failure') {
              reject(data);
            }
          });
        });
      };
      
      const executeAsyncCalls = async () => {
        const orderLinesToDeleteAsPayloads = [];
        
        location.orderLines.forEach(orderLine => {
          orderLinesToDeleteAsPayloads.push({
            LineId: orderLine.lineId,
            ShoppingCartId: null,
            ProductId: orderLine.productId,
            Comments: orderLine.comments,
            Quantity: orderLine.quantity,
            UnitCode: orderLine.unitCode,
            IsFree: orderLine.isFree
          });
        });

        const promises = orderLinesToDeleteAsPayloads.map(payload => asyncFunction(payload));
        const results = await Promise.allSettled(promises);

        var isSuccess = true;
        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
          } else {
            isSuccess = false;
            window.updateErrorMessage(response.Message);
          }
        });

        if (isSuccess) {

          var newTotalCount = results[0].value.TotalsCount;
          var newTotals = results[0].value.Totals;

          results.forEach(result => {
            var deletedLine = result.value.LineToBeDeleted;
            var totalsCount =  result.value.TotalsCount;
            var totals = result.value.Totals;
            
            // Find the lowest new totals and set that as the totals we will use
            // to update the totals in state after delting all the location order lines
            if (totalsCount.ProductCount < newTotalCount.ProductCount) {
              newTotalCount = totalsCount;
              newTotals = totals;
            }

            commit('deleteProjectOrderLine', {
              orderLineId: deletedLine.LineId,
              totalCount: deletedLine.TotalCount,
              productId: deletedLine.ProductId
            });  
          });
          
          commit('setTotalAndCount', newTotalCount.ProductCount);
          commit('setShoppingCartTotals', newTotals.Prices);
          
          if (rootGetters.stockLimit) {
            commit('checkStockState');
            dispatch('renderCheckoutButton');
          }
          if (getters.orderLines.length === 0 && getters.projectOrderLines.locations.length === 0) {
            commit('setShoppingCartEmpty', true);
          }

          resolveDeleteLocation();

        }
        
      };
      executeAsyncCalls();
    });

    
    
  },
  // END BESPOKE VLINT
  applyCoupon ({ rootGetters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.addCouponEndpoint, { couponCode: payload })
        .then(res => {
          const response = res.data.d;
          const status = response.Status;
          if (status === 'Success') {
            commit('setCouponCode', { CouponCode: payload, HasCoupon: true });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
            // BESPOKE VLINT-180
            // After applying coupon, retrieve the orderlines since the customer wants to add a 
            // free product by coupon, which needs to retrieve the orderline
            dispatch('getOrderLines');
            // END BESPOKE VLINT-180
          } else if (status === 'CouponUsed' || status === 'NotFound') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            reject(response.Message);
          }
        });
    });
  },
  removeCoupon ({ rootGetters, getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.removeCouponEndpoint, { couponCode: getters.couponCode })
        .then(res => {
          const response = res.data.d;
          if (response.Status === 'Success') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
             // BESPOKE VLINT-180
            // After applying coupon, retrieve the orderlines since the customer wants to add a 
            // free product by coupon, which needs to retrieve the orderline
            dispatch('getOrderLines');
            // END BESPOKE VLINT-180
          } else if (response.Status === 'Failure') {
            window.updateErrorMessage(response.Message);
            reject();
          }
        });
    });
  },
  emptyShoppingCart ({ rootGetters, commit }) {
    axios.post(rootGetters.emptyShoppingCartEndpoint, {}).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {
        commit('emptyShoppingCart');
        commit('setShoppingCartEmpty', true);
        window.updateOrderMessage(data.Message);
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.Message);
      }
    });
  },
  emptyShoppingCartPromise ({ rootGetters, commit }, showConfirmMessage = true) {
    //BESPOKE
    return new Promise((resolve) => {
      axios.post(rootGetters.emptyShoppingCartEndpoint, {}).then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          commit('emptyShoppingCart');
          commit('setShoppingCartEmpty', true);
          if (showConfirmMessage) {
            window.updateOrderMessage(data.Message);
          }
        } else if (data.Status === 'Failure') {
          window.updateErrorMessage(data.Message);
        }
        resolve();
      });
    });    
  },
  renderCheckoutButton ({ getters }) {
    if (!getters.showCheckoutButton) {
      window.$('.checkout-button').addClass('disabled');
    } else {
      window.$('.checkout-button').removeClass('disabled');
    }
  },
  hidePageElements () {
    window.$('.vuejs-hook').hide();
  },
  checkout () {
    window.$('.uc_payment .inputbutton').click();
  },
  // BESPOKE VLINT-21
  // Added projectName as a parameter, since this is now needed in the case
  // products are added to cart as a project. This field will then be used
  // to fill the order description
  addToCart ({ rootGetters, commit, dispatch }, { payload, projectName }) {
    let endpoint = rootGetters.orderProductEndpoint;
    let userLoggedOn = rootGetters.userLoggedOn;
    const products = Array.isArray(payload) ? payload : [payload];

    return new Promise((resolve, reject) => {
      // BESPOKE VLINT-21
      // Added projectName as a parameter to the webmethod, since this is now needed in the case
      // products are added to cart as a project. This field will then be used to fill the order description
      axios.post(endpoint, JSON.stringify({ products: products, loggedInStatus: userLoggedOn, projectName: projectName })).then(
        res => {
          const response = res.data.d;
          if (response.errorMessages) {
            if (response.errorMessages === 'log in status changed') {
              window.location.replace('/webshop/login.aspx?RedirectUrl=' + window.location.pathname + window.location.search);
            } else {
              window.updateErrorMessage(response.errorMessages);
              // BESPOKE VLINT
              // If some products are unavailable, retrieve the new shoppingcart state
              if (response.someProductsUnavailable) {     
                dispatch('getShoppingCartTotalAndCount');
              }
              // END BESPOKE VLINT
            }
          } else {
            resolve(true);
            dispatch('getShoppingCartTotalAndCount');

            // REFERS TO FUNCTION CALLS IN CUSTOM.JS
            if (response.errorMessages) {
              window.updateErrorMessage(response.errorMessages);
            }

            var message = response.order_message;
            if (response.totalProductsAdded > 0) {
              // BESPOKE VLINT
              // Show a vuejs component with the success message instead
              // of the default success message
              commit('setShowAddedToCartMessage', true);
              setTimeout(function() {                
                commit('setShowAddedToCartMessage', false);
              }, 10000);
              // window.updateOrderMessage(message);
              // END BESPOKE VLINT
            }
            // END CUSTOM.JS FUNCTION CALLS
          }
        })
        .catch(err => {
          // @TODO Error handling
          reject(err);
        });
    });
  },
  // BESPOKE VLINT
  setShowAddedToCartMessage({ commit }, showMessage) {
    commit('setShowAddedToCartMessage', showMessage);
  }
  // END BESPOKE VLINT
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
