import axios from 'axios';
import { Product } from 'src/models/product.model';
import { getProductInformation }  from './../../../src/services/product-service';

const state = {
  searchText: '',
  autocompleteData: [],
  productsFound: false,
  searchConfig: {
    UserId: null,
    Category: null,
    StringProperties: null,
    NumericProperties: null,
    StringAggregations: null,
    NumericAggregations: null,
    ExtraQueries: []
  },
  selectedProduct: {},
  // BESPOKE
  representativeProducts: [],
  loadedRepresentativeProducts: false
  // END BESPOKE
};

const getters = {
  searchText(state) {
    return state.searchText;
  },
  searchConfig(state) {
    return {
      UserId: null,
      Category: state.searchConfig.Category,
      StringProperties: null,
      NumericProperties: null,
      StringAggregations: state.searchConfig.StringAggregations,
      NumericAggregations: null,
      ExtraQueries: state.searchConfig.ExtraQueries,
      ExcludeLabels: state.searchConfig.ExcludeLabels,
      ExcludeCategories: state.searchConfig.ExcludeCategories,
      IncludeLabels: state.searchConfig.IncludeLabels,
      ShouldIncludeLabels: state.searchConfig.ShouldIncludeLabels
    }
  },
  elasticProductsEndpoint(state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.elasticProductsEndpoint;
    const from = 0;
    const language = rootGetters.language;
    const size = 50;
    const aggregations = false;
    const pricingQueryString = '';
    const customerListId = '';
    const query = state.searchText;
    const customerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    const client = rootGetters.clientCode;
    const priceListCode = rootGetters.customerPriceList;
    const groupProducts = false;
    const sort = null;
    const sortingType = '';
    var sortingstring = ''

    if (sort !== null) sortingstring = '&sort=' + sort + '|' + sortingType;

    return `${endpoint}/GetProductCodeAndDesc?lang=${language}${sortingstring}&from=${from}&size=${size}&aggr=${aggregations}&query=${query}&customerId=${customerId}&customerPricelist=${priceListCode}&listId=${customerListId}&client=${client}&pricingQuerystring=${pricingQueryString}&groupProducts=${groupProducts}`;
  },
  productInformationEndpoint(state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}?language=${language}`;
  },
  productsFound(state) {
    return state.productsFound;
  },
  autocompleteData(state) {
    return state.autocompleteData;
  },
  // BESPOKE
  representativeProducts(state) {
    return state.representativeProducts;
  },
  loadedRepresentativeProducts(state) {
    return state.loadRepresentativeProducts;
  },
  queriedRepresentativeProducts: (state) => (searchString) => {
    if (searchString == '') {
      return [];
    }
    var queryString = searchString.toLowerCase();
    const filtedProducts = state.representativeProducts.filter(x => x.key.toLowerCase().includes(queryString) || x.value.toLowerCase().includes(queryString));
    return filtedProducts;
  }
  // END BESPOKE
};

const mutations = {
  setSearchText(state, payload) {
    state.searchText = payload;
  },
  setAutoCompleteData(state, payload) {
    // BESPOKE VLINT-193
    // Exclude representative products from uws_settings.isrep_proj_prod if the loggedin user
    // is not a representative
    const globalBespokeGetters = vuexStore.$store.getters.bespokeGlobalWs;
    const isRepresentative = globalBespokeGetters.isRepresentative;
    
    if (!isRepresentative) {
      const keysToExclude = globalBespokeGetters.representativeProducts;
      const filteredArray = payload.filter(item => !keysToExclude.includes(item.key));
      payload = filteredArray;
    } 
    // END BESPOKE VLINT-193
    state.autocompleteData = payload;
  },
  initSearchConfig(state, searchConfig) {
    state.searchConfig = { ...searchConfig };
  },
  productsFound(state, payload) {
    state.productsFound = payload;
    if (!payload) {
      state.selectedProduct = [];
    }
  },
  setSelectedProduct(state, payload) {
    state.selectedProduct = payload;
  },
  setProductPrice(state, productWithPrice) {
    state.selectedProduct = productWithPrice;
  },
  setProductStock(state, productWithStock) {
    state.selectedProduct = productWithStock;
  },
  // BESPOKE
  setRepresentativeProducts(state, representativeProducts) {
    state.representativeProducts = representativeProducts;
  },
  setLoadedRepresentativeProducts(state, val) {
    state.loadedRepresentativeProducts = val;
  }
  // END BESPOKE
};

const actions = {
  // BESPOKE VLINT
  // Get representative products through a different endpoint, as they are not found through the order entry search endpoint
  // so we retrieve the product information based on the product codes provided from the CMS
  loadRepresentativeProducts({commit, getters}) {

    if (getters.loadedRepresentativeProducts) {
      return;
    }

    commit('setLoadedRepresentativeProducts', true);
    const globalBespokeGetters = vuexStore.$store.getters.bespokeGlobalWs;
    const isRepresentative = globalBespokeGetters.isRepresentative;
    const representativeProducts = globalBespokeGetters.representativeProducts;
    
    const availableRepresentativeProducts = getters.searchText !== '' ? representativeProducts.filter(x => x.includes(getters.searchText)) : [];

    if (isRepresentative && availableRepresentativeProducts) {
      
      var payload = { 
        prodCodes: globalBespokeGetters.representativeProducts,
        getStock: false, 
        getPrices: false,
        getCustomerLists: false,
        getSeoInformation: false
      };

      // Get data for representative products
      getProductInformation(payload).then(representativeProductsResponse => {
        
        const representativeProducts = representativeProductsResponse.products;
        let representativeProductsAsOrderEntryFormat = representativeProducts.map(x => ({ key: x.id, value: x.shortDescription }));
        commit('setRepresentativeProducts', representativeProductsAsOrderEntryFormat);
      });
    }
  },
  queryRepresentativeProducts({ getters }, searchString) {
    if (searchString == '') {
      return [];
    }

    const filtedProducts = getters.representativeProducts.filter(x => x.key.includes(searchString) || x.value.includes(searchString));
    return filtedProducts;
  },
  // END BESPOKE VLINT
  getAutocompleteData({ commit, getters, dispatch, rootGetters, state }) {
    if (getters.searchText !== '') {
      axios.post(getters.elasticProductsEndpoint, getters.searchConfig)
        .then(res => {
          if (res.data) {

            // BESPOKE
            var autocompleteData = res.data;
            
            var representativeProducts = getters.queriedRepresentativeProducts(getters.searchText);

            // Check if we need to add any representative products to the autocomplete data
            if (representativeProducts.length) {
              autocompleteData = representativeProducts.concat(autocompleteData);
            }

            commit('setAutoCompleteData', autocompleteData);
            commit('productsFound', true);           
          } else {
            commit('productsFound', false);
          }
        })
        .catch(error => {
          var autocompleteData = [];
          // In the case no products were found, check if the representative products may need to be shown
          var representativeProducts = getters.queriedRepresentativeProducts(getters.searchText);

          // Check if we need to add any representative products to the autocomplete data
          if (representativeProducts.length) {
            autocompleteData = representativeProducts.concat(autocompleteData);
          }

          commit('setAutoCompleteData', autocompleteData);
          commit('productsFound', autocompleteData.length > 0);
        });
    } else {
      commit('setAutoCompleteData', []);
      commit('productsFound', false);
    }
  },
  clearSelectedProduct({ commit }) {
    commit('productsFound', false);
    commit('setAutoCompleteData', []);
  },
  getProductInformation({ commit, getters, dispatch, rootGetters }) {
    let acData = getters.autocompleteData;

    if (acData.filter((x) => x.key === getters.searchText).length > 0) {
      let mainProductCodes = [getters.searchText];

      axios.post(getters.productInformationEndpoint, mainProductCodes)
      .then(res => {
        if (res.data.length) {
          const products = res.data.map(product => new Product(product));

          if (rootGetters.showStock) {
            dispatch('getProductStock', products);
          } else {
            products.forEach(product => { product.setStock({ stockTotal: 0 }); });
          }

          if (rootGetters.showPrices) {
            dispatch('getProductPrices', products);
          }

          commit('setSelectedProduct', products[0]);

          if (products[0].customBooleans.NOT_AVAILABLE_PROD) {
            dispatch('getReplacements', products[0].id)
          }
        }
        commit('setSearchText', '');
        commit('setAutoCompleteData', []);
        commit('productsFound', false);
      });
    }    
  },
  getProductStock({ commit, rootGetters, dispatch }, products) {
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          product.setStock(res.data.d);
          commit('setProductStock', product);
        });
    });
  },
  getProductPrices({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          commit('setProductPrice', product);
        });
      });
  },
  updateSearchText({ commit, getters, dispatch, rootGetters }, payload) {
    commit('setSearchText', payload)
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
