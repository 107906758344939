<template>
  <div class="cmp-product-price" v-if="showPrices" :class="{ 'incl-excl': priceType, [parentClassRef]: parentClassRef  }">
    <ul class="price-list flex-row-nowrap align-items-baseline">
      <li class="default">
        <span>{{ prices.price }}</span>
      </li>
      <li v-if="prices.isSalesAction" class="original salesaction">
        <span>{{ prices.basePrice }}</span>
      </li>
      <li v-if="showExclVat" class="vat-notation">
        <span v-translation="{ type: 'label', code: 'label_price1_excl_vat' }"></span>
      </li>
    </ul>
    <ul v-if="showExclVat" class="price-list flex-row-nowrap align-items-baseline price-incl">
      <li class="default">
        <span>{{ prices.priceIncl }}</span>
      </li>
      <li v-if="prices.isSalesAction" class="original salesaction">
        <span>{{ prices.basePriceIncl }}</span>
      </li>
      <li class="vat-notation"><span v-translation="{ type: 'label', code: 'label_price1_incl_vat' }"></span></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false }
  },
  computed: {
    ...mapGetters(['showPrices', 'priceType']),
    showExclVat() {
      return this.priceType === 3;
    }
  }
}
</script>

<style>

</style>
