<template>
  <div class="cmp-shopping-cart-totals" v-if="showPrices && Object.keys(shoppingCartTotals).length">
    <ul>
      <li v-for="val in format" :key="val" class="utlz-col-12 flex-row-nowrap justify-content-between totals-line">
        <span v-translation="{ type: 'label', code: val }"></span>
        <span>{{ shoppingCartTotals[val].price }}</span>
      </li>
    </ul>

    <template v-if="useMinimumOrderQuantity && !minimumOrderQuantityValid">
      <div class="cmp-minimum-order-quantity">
        <div class="utlz-col-md-12 min-order-quantity-message alert alert-warning">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <h2 class="header-small">
            {{ warning }}
          </h2>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatPrice } from 'src/utilities/helpers';
export default {
  props: {
    format: { type: Array, required: true }
  },
  data () {
    return {
      warning: ''
    };
  },
  computed: {
    ...mapGetters(['showPrices', 'useMinimumOrderQuantity']),
    ...mapGetters('shoppingCart', ['shoppingCartTotals', 'minimumOrderQuantityPrice', 'minimumOrderQuantityRawPrice', 'minimumOrderQuantityValid', 'orderLinesTotalRawPrice'])
  },
  methods: {
    setWarning () {
      const warning = window.vue.translations.label.label_minimum_order_quantity;
      const remainingPrice = formatPrice(this.minimumOrderQuantityRawPrice - this.orderLinesTotalRawPrice);
      this.warning = warning.replace('[1]', this.minimumOrderQuantityPrice).replace('[2]', remainingPrice);
    }
  },
  watch: {
    orderLinesTotalRawPrice () {
      this.setWarning();
    }
  },
  created () {
    this.setWarning();
  }
}
</script>

<style>

</style>
