<template>
  <div v-if="showDropdown" class="cmp-order-units utlz-reset">
    <div class="custom-select-box">
      <div class="current" @click="selectExpanded = !selectExpanded" v-outside-click="{ handler: 'closeDropdown', exclude: ['dropdown']}" ref="dropdown">
        <span class="unit-description">{{ units[selectedIndex].description }}:</span>
        <span class="unit-quantity">{{ units[selectedIndex].quantity }}</span>
        <span v-translation="{ type: 'label', code: 'label_unit_pieces' }"></span>
        <i class="fa fa-angle-down"></i>
      </div>
      <ul v-if="selectExpanded" class="options">
        <li v-for="(unit, index) in units"
          :key="index"
          @click="updateSelectedUnits(index)"
          :class="{ 'selected': selectedIndex === index, 'disabled': unitOutOfStock(unit.quantity) }"
          class="option">
          <span class="unit-description">{{ unit.description }}:</span>
          <span class="unit-quantity">{{ unit.quantity }}</span>
          <span v-translation="{ type: 'label', code: 'label_unit_pieces' }"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    units: { type: Array, required: true },
    stockTotal: null,
    selectedUnitCode: { type: String, required: false },
    stockProduct: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      selectExpanded: false
    }
  },
  computed: {
    ...mapGetters(['stockLimit']),
    selectedIndex () {
      if (this.selectedUnitCode) {
        return this.units.map(unit => unit.code).indexOf(this.selectedUnitCode);
      } else {
        return 0;
      }
    },
    unitOutOfStock (quantity) {
      return (quantity) => {
        if (this.stockLimit) {
          return this.stockProduct ? this.stockTotal < quantity : false;
        } else {
          return false;
        }
      };
    },
    showDropdown () {
      // Do not display dropdown if product only has one unit with quantity 1
      if (this.units.length === 1 && this.units[0].quantity === 1) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    expandSelect () {
      this.selectExpanded = true;
    },
    updateSelectedUnits (index) {
      this.$emit('unitsChanged', {
        quantity: this.units[index].quantity,
        unitCode: this.units[index].code
      });
    },
    closeDropdown () {
      this.selectExpanded = false;
    }
  }
};
</script>

<style>
</style>
