<template>
  <div class="cmp-static-modal">
    <div class="full-screen-fader flex-column justify-content-center">
      <div class="utlz-container">
        <div class="utlz-row">
          <div class="utlz-col-md-8 utlz-offset-md-2">
            <div class="modal-content">
              <slot name="static-modal-content"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
