<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row">
      <div class="utlz-col-md-4 column-left">
        <utlz-gallery :product="productDetails"></utlz-gallery>
      </div>

      <!-- BESPOKE VLINT: If a user is not logged in we show a completely different panel with usps and login information -->
      <template v-if="!userLoggedOn">
        <div class="utlz-col-md-6 not-logged-in column-right">
          <div class="inner-wrapper">
            <h1>{{ productDetails.shortDescription }}</h1>
            <template v-if="grossPrice !== null">
              <div class="gross-price">
                <p>{{ grossPrice }}</p>
                <div class="price-note" v-translation="{ type: 'text', code: 'text_login_net_price' }">
                  
                </div>
              </div>
            </template>
            <utlz-product-stock
                :product="productDetails"
                :stock="productDetails.stock"
                :stockTotal="productDetails.stock"
              ></utlz-product-stock>
            <div class="order-product">
              <utlz-order-product
                v-if="showOrderProductControl"
                :product="productDetails"
                :useOrderComment="useOrderLineComments"
                >
                </utlz-order-product>

                <transition name="fade">
                  <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                    <i class="fa fa-warning"></i>
                    <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                  </p>
                </transition>
            </div>
            <div class="login-usp checkmark-list">            
              <div v-html="textNotLoggedInUsp"></div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="utlz-col-md-8 column-right product-details-column">
            <div class="product-information">

              <!-- BESPOKE VLINT: Changed image banners to a seperate control, should be in a future release -->
              <utlz-product-banners :banners="productDetails.productImageBanners"></utlz-product-banners>
              <!-- END BESPOKE VLINT -->

              <h1>{{ productDetails.shortDescription }}</h1>
              <div class="product-code">
                  <span>{{ productDetails.id }}</span>
              </div>
              <div class="ph-product-price" v-if="productDetails.prices.length">
                  <utlz-product-price
                      :prices="productDetails.prices[0]"
                  ></utlz-product-price>
              </div>
              
              <utlz-group-dropdown :variants="productVariants" />

              <utlz-product-stock
                :product="productDetails"
                :stock="productDetails.stock"
                :stockTotal="productDetails.stock"
              ></utlz-product-stock>

              <div class="order-product">
                <utlz-order-product
                  v-if="showOrderProductControl"
                  :product="productDetails"
                  :useOrderComment="useOrderLineComments"
                  >
                  </utlz-order-product>

                  <transition name="fade">
                    <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                      <i class="fa fa-warning"></i>
                      <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                    </p>
                  </transition>
              </div>

              <!-- BESPOKE VLINT: Added usp list -->
              <div v-html="textLoggedInUsp" class="loggedin-usp checkmark-list">      
              </div>
              <!-- END BESPOKE VLINT -->

              <div class="favorite">
                <utlz-favorites-control v-if="showFavorites" :product="productDetails" :useDescription="true"></utlz-favorites-control>
              </div>
            </div>
        </div>
      </template>

      <!-- BESPOKE VLINT: Added two columns and added the product information and certificate banners -->
      <div class="utlz-col-md-12 secondary-row">
        <div class="utlz-row">
          <div class="utlz-col-lg-6 prod-info-column">
            <utlz-tabs :tabs="tabs">

              <!-- BESPOKE HWS: Grouped specification under a tab and disabled header -->
              <template v-slot:specs>
                <div class="product-specifications">
                <!-- <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2> -->
                <div class="spec-list">
                  <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
                    <div class="spec-desc">
                      {{prop.key}}
                    </div>
                    <div class="spec-value">
                      {{prop.value}}
                    </div>
                  </div>
                </div>
              </div>
              </template>
              <!-- END BESPOKE HWS -->

              <template v-slot:description>
                <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
                  <div ref="productDescription" class="prod-desc-wrapper">
                    <div class="product-long-description" v-html="productDetails.longDescription">
                    </div>
                  </div>
                </div>
                <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
                  <div v-if="!expanded" class="read-more">
                    <span v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                  <div v-if="expanded" class="read-less">
                    <span v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </a>
              </template>


              <template v-slot:videos v-if="videos.length">
                <div class="product-videos">
                  <utlz-product-videos :videos="videos"></utlz-product-videos>
                </div>
              </template>

              <template v-slot:documents v-if="documents.length">
                <div class="product-documents">
                  <utlz-product-documents :documents="documents"></utlz-product-documents>
                </div>
              </template>

            </utlz-tabs>
          </div>
          <div class="utlz-col-lg-6 ">
            <!-- BESPOKE VLINT-155: Added tabs for category info banners -->
            <utlz-category-info-tabs></utlz-category-info-tabs>
            <div id="ph-contact-me"></div>
          </div>
          <!-- END BESPOKE VLINT-155 -->
        </div>
        <!-- END BESPOKE VLINT -->


      </div>

      <div class="utlz-col-12 margin-bottom-30"></div>

      <div v-if="showOrderProductControl && productVariants.length" class="utlz-col-md-12">
        <utlz-group-matrix :variants="productVariants" />
      </div>

      <div v-if="relatedProducts.upSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.upSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleUpSelling"
        ></utlz-product-carousel>
      </div>

      <div v-if="relatedProducts.crossSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.crossSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleCrossSelling"
        ></utlz-product-carousel>
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductGroupMatrix from 'src/components/webshop/products/product-group/ProductGroupMatrix.vue';
import ProductGroupDropdown from 'src/components/webshop/products/product-group/ProductGroupDropdowns.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
import Gallery from 'src/components/ui/gallery/Gallery.vue';
import Tabs from 'src/components/ui/tabs/Tabs.vue';
import ProductVideos from 'src/components/webshop/products/product-videos/ProductVideos.vue';
import ProductDocuments from 'src/components/webshop/products/product-documents/ProductDocuments.vue';
import ProductCarousel from 'src/components/webshop/products/product-carousel/ProductCarousel.vue';
// BESPOKE VLINT
import CertificateBanners from './../../../../certificate-banners/CertificateBanners.vue';
import CategoryInfoTabs from './../../../../category-info-tabs/CategoryInfoTabs.vue';
import ProductBanners from 'src/components/webshop/banners/ProductBanners.vue';
import { getProductPriceBespoke  } from '../../../../../src/services/product-service';
// END BESPOKE VLINT
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-product-stock': ProductStock,
    'utlz-group-matrix': ProductGroupMatrix,
    'utlz-group-dropdown': ProductGroupDropdown,
    'utlz-favorites-control': FavoritesControl,
    'utlz-gallery': Gallery,
    'utlz-tabs': Tabs,
    'utlz-product-carousel': ProductCarousel,
    'utlz-product-videos': ProductVideos,
    'utlz-product-documents': ProductDocuments,
    // BESPOKE VLINT
    'utlz-certificate-banners': CertificateBanners,
    'utlz-product-banners': ProductBanners,
    'utlz-category-info-tabs': CategoryInfoTabs
    // END BESPOKE VLINT
  },
  data () {
    return {      
      movedContactBlock: false,
      expanded: false,
      productDescriptionHeight: 0,
      titleUpSelling: window.vue.translations.title.title_product_relations,
      titleCrossSelling: window.vue.translations.title.title_product_relations_cross,
      tabs: [
        { id: 'specs', description: window.vue.translations.title.title_specifications, active: true, visible: true },
        // BESPOKE VLINT
        // Added product specs as tab
        { id: 'description', description: window.vue.translations.label.label_tab_product_desc, active: false, visible: false },
        // END BESPOKE VLINT
        { id: 'documents', description: window.vue.translations.label.label_tab_product_documents, active: false, visible: false },
        { id: 'videos', description: window.vue.translations.label.label_tab_product_videos, active: false, visible: false }
      ],
      // BESPOKE VLINT
      textNotLoggedIn: window.vue.translations.text.text_not_loggedin,
      textNotLoggedInUsp: window.vue.translations.text.text_not_loggedin_usp,
      textLoggedInUsp: window.vue.translations.text.text_loggedin_usp,
      grossPrice: null,
      setHiddenFields: false      
      // END BESPOKE VLINT
    }
  },
  watch: {
    // BESPOKE VLINT
    // Added watcher for specs tab and changed indexed to correspond with positions of tabs in data.tabs array
    longDescription (val) { this.tabs[1].visible = val == true; },
    documents (val) { this.tabs[2].visible = val.length > 0; },
    videos (val) { this.tabs[3].visible = val.length > 0; },    
    productProperties (val) { this.tabs[0].visible = val.length > 0; },
    productDetails (val) {
      if (val.id !== 'NOTLOADED') {
          // BESPOKE
          if (!this.userLoggedon) {
            getProductPriceBespoke([this.productDetails], null, true).then(res => {
              this.grossPrice = res[0].prices[0].basePrice
            });
          }
          // END BESPOKE
      }
    }
    // END BESPOKE VLINT
  },
  computed: {
    ...mapState('productDetails', ['productDetails', 'productVariants', 'relatedProducts', 'videos']),
    // BESPOKE VLINT
    // Added userLoggedOn
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'relatedProductBlockSize','userLoggedOn']),
    ...mapGetters('productDetails', ['documents']),
    // BESPOKE VLINT
    // Added longDescription  
    longDescription () {
      return this.productDetails.longDescription.length > 0;
    },
    // END BESPOKE VLINT
    useCollapse () {
      // BESPOKE VLINT
      // Do not use read more functionality
      return false;
      // END BESPOKE VLINT
      return this.productDescriptionHeight > 150;
    },
    showOrderProductControl () {
      if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.productDetails.stock !== null) {
          return this.stockLimit && this.productDetails.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      return Object.keys(this.productDetails.properties).map(x => {
        return { key: x, value: this.productDetails.properties[x] }
      });
    },
    images () {
      let images = [];

      images.push(this.productDetails.imageUrl)

      this.productDetails.images.forEach(element => {
        images.push(element);
      });

      return images
    }
  },
  updated () {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }

    // BESPOKE VLINT-146
    if (this.productDetails.id && !this.setHiddenFields) {
      $('[data-prod-code="True"]').val(this.productDetails.id);

      var title = '';
      if (this.productDetails.variantKey) {
        var groupDescription = this.productDetails.customStrings["GroupDescription_" + this.language.toUpperCase()];
        title = !groupDescription ? this.productDetails.shortDescription : groupDescription;
      } else {
        title = this.productDetails.shortDescription;
      }

      $('[data-prod-desc="True"]').val(title);
      this.setHiddenFields = true;
    }

    
    // TODO
    // Replace with custom contact form
    if (!this.movedContactBlock && $('#ph-contact-me').length > 0) {
      $('#ph-contact-me').append($(".prod-info-form"));
      $('#ph-contact-me .header-wrapper').prepend($('#ph-contact-me h2'));
      this.movedContactBlock = true;
    }
    // END BESPOKE VLINT-146
  },
  created () {
    this.initDetails();
  },
  methods: {
    ...mapActions('productDetails', ['initDetails'])
  }
}
</script>

<style>

</style>
