<template>
  <div class="cmp-shopping-cart-control utlz-reset">

    <!-- BESPOKE VLINT -->
    <utlz-shopping-cart-message 
    :shoppingCartUrl="shoppingCartUrl"
    ></utlz-shopping-cart-message>
    <!-- END BESPOKE VLINT -->

    <div class="icon-wrapper" @click="toggleCart()">
      <!-- BESPOKE VLINT: Changed shoppingcart icon -->
      <!-- <i class="uws-icon uws-cart" aria-hidden="true"></i> -->
      <img src="/Bespoke/Assets/Images/shopping_cart_icon.svg" class="cart-icon" alt="shopping cart icon"/>
      <!-- END BESPOKE VLINT -->
      <span class="total-counter btn-u">{{ totalProductsCount }}</span>
    </div>

    <transition name="fadein-cart">
      <div v-if="cartOpen">

        <div @click="toggleCart()" class="close-target"></div>

        <div class="shopping-cart-panel">

          <span class="close-button close-panel-mobile" @click="toggleCart()">
            <i class="fa fa-close" aria-hidden="true"></i>
          </span>

          <div class="panel-header flex-row-nowrap align-baseline">
            <!-- BESPOKE: Disabled icon -->
            <!-- <i class="uws-icon uws-cart" aria-hidden="true"></i> -->
            <!-- END BESPOKE -->
            <h2>
              <span v-translation="{ type: 'title', code: 'title_shopping_cart_header' }"></span>
              <span class="counter">({{ totalProductsCount }})</span>
            </h2>
          </div>

          <div v-if="loadingOrderLines">
            <div v-for="index in 4" :key="index" class="ph-silhouette utlz-row">
              <span class="silhouette-img utlz-col-2"></span>
              <div class="ph-paragraph utlz-col-10">
                <span class="silhouette-line"></span>
                <span class="silhouette-line"></span>
              </div>
            </div>
          </div>

          <template v-if="showOrderLines">
            <ul class="order-lines">
              
              <!-- BESPOKE VLINT: Seperately render the project list orderlines -->
              <li v-for="(location, index) in projectOrderLines.locations" :key="location.code.toString() + index" class="project-orderline-row flex-row-nowrap">
                <div v-for="orderLine in location.orderLines" :key="orderLine.lineId" class="project-orderline">
                  <div v-if="!hideShoppingCartImages" class="prod-img">
                    <template v-if="orderLine.product.imageUrl">
                      
                    <img :src="orderLine.product.imageUrl ? orderLine.product.imageUrl : '/documents/productimages/not-available-small.jpg'" />
                    </template>
                  </div>
                  <div class="prod-desc">
                    <a :href="orderLine.product.targetUrl">
                      <h3 class="prod-title header-small">{{ orderLine.product.shortDescription }}</h3>
                    </a>
                    <div class="quantity-wrapper">                    
                      <span class="quantity">{{ orderLine.computedQuantity }} x</span>
                      <utlz-product-price
                        :prices="orderLine.totals"
                        v-if="orderLine.product.prices.length"
                      ></utlz-product-price>
                    </div>
                  </div>
                  <div class="delete">
                    <span class="close-button small grey" @click="deleteOrderLine(orderLine)">
                      <i class="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </li>
              <!-- END BESPOKE VLINT -->

              <li v-for="orderLine in orderLines" :key="orderLine.lineId" class="orderline-row flex-row-nowrap">
                <div v-if="!hideShoppingCartImages" class="prod-img">
                  <template v-if="orderLine.product.imageUrl">
                    <img :src="orderLine.product.imageUrl ? orderLine.product.imageUrl : '/documents/productimages/not-available-small.jpg'" />
                  </template>
                </div>
                <div class="prod-desc">
                  <a :href="orderLine.product.targetUrl">
                    <h3 class="prod-title header-small">{{ orderLine.product.shortDescription }}</h3>
                  </a>
                  <div class="quantity-wrapper">                    
                    <span class="quantity">{{ orderLine.computedQuantity }} x</span>
                    <utlz-product-price
                      :prices="orderLine.totals"
                      v-if="orderLine.product.prices.length"
                    ></utlz-product-price>
                  </div>
                </div>
                <div class="delete">
                  <!-- BESPOKE VLINT: Pass orderLine instead of lineId -->
                  <span class="close-button small grey" @click="deleteOrderLine(orderLine)">
                  <!-- END BESPOKE VLINT -->
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                  </span>
                </div>
              </li>
            </ul>
          </template>

          <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
            <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
          </div>

          <div class="fade-white"></div>

          <utlz-shopping-cart-totals v-if="showOrderLines" :format="shoppingCartControlTotalsFormat"></utlz-shopping-cart-totals>
          
          <!-- BESPOKE VLINT: Removed if statement, since the customer wants to be able to only add one free product to the cart -->
          <div  class="actions">
            <!-- END BESPOKE -->
            <a :href="shoppingCartUrl" class="button theme-primary block">
              <!-- BESPOKE -->
              <!-- <i class="uws-icon uws-cart" aria-hidden="true"></i> -->
              <!-- END BESPOKE -->
              <span v-translation="{ type: 'button', code: 'button_view_shopping_cart' }"></span>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
// BESPOKE VLINT
import ShoppingCartMessage from './ShoppingCartMessage.vue';
// END BESPOKE VLINT

export default {
  components: {
    'utlz-shopping-cart-totals': ShoppingCartTotals,
    'utlz-product-price': ProductPrice,
    // BESPOKE VLINT
    'utlz-shopping-cart-message': ShoppingCartMessage
    // END BESPOKE VLINT
  },
  data () {
    return {
      cartOpen: false
    }
  },
  computed: {
    ...mapGetters(['language', 'hideShoppingCartImages']),
    // BESPOKE VLINT
    // Added projectOrderLines
    ...mapGetters('shoppingCart', ['orderLines', 'projectOrderLines',  'loadingOrderLines', 'totalProductsCount', 'shoppingCartEmpty', 'shoppingCartControlTotalsFormat']),
    shoppingCartUrl () {
      if (!this.userLoggedOn) {        
        return `/${this.language}/webshop/paymentprocess/shopping_cart.aspx`;
      } else {      
        return `/${this.language}/webshop/paymentprocess/single_checkout.aspx`;
      }
    },
    showOrderLines () {
      // Also take project orderlines into account
      var orderLinesRetrieved = this.orderLines.length > 0 && this.orderLines[0].product !== null;
      var projectOrderLinesRetrieved = this.projectOrderLines.locations.length > 0 && this.projectOrderLines.locations[0].orderLines[0].product !== null; 
      var showOrderLines = orderLinesRetrieved || projectOrderLinesRetrieved;
      return showOrderLines;
      // END BESPOKE VLINT
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['initShoppingCart', 'getShoppingCartTotalAndCount', 'deleteOrderLine']),
    toggleCart () {
      // BESPOKE
      $('.uc_header_v6').toggleClass('open-cart');
      // END BESPOKE
      this.cartOpen = !this.cartOpen;
      if (this.cartOpen && !window.vue.modules.shoppingCartOverview) {
        this.initShoppingCart();
      }
    }
  },
  created () {
    this.getShoppingCartTotalAndCount();
  }
};
</script>

<style>
</style>
