import axios from 'axios';

const state = {
  queryString: "",
  projects: [],
  selectedProjectCode: "",
  selectedOrderHistoryDetails: {},
  noResultsFound: false,
  noProjectsFound: false,
  retrievingProjects: false
};

const getters = {
  projects(state) {
    return state.projects;
  },
  queryString(state) {
    return state.queryString;
  },
  selectedOrderHistoryDetails(state) {
    return state.selectedOrderHistoryDetails;
  },
  selectedProjectCode(state) {
    return state.selectedProjectCode;
  },
  noResultsFound(state) {
    return state.noResultsFound;      
  },
  noProjectsFound(state) {
    return state.noProjectsFound;
  },
  retrievingProjects(state) {
    return state.retrievingProjects;
  }
};

const mutations = {
  setRetrievingProjects(state, retrieving) {
    state.retrievingProjects = retrieving;
  },
  setNoResultsFound(state, noResultsFound) {
    state.noResultsFound = noResultsFound;
  },
  setNoProjectsFound(state, noProjectsFound) {
    state.noProjectsFound = noProjectsFound; 
  },
  setQueryString(state, queryString) {
    state.queryString = queryString;
  },
  setProjects(state, payload) {
    const projects = [];     
    payload.forEach(project => {
      projects.push({
        ProjectCode: project.ProjectCode,
        ProjectDescription: project.ProjectDescription,
        OrderHistoryLines: null,
        Active: true
      });
    });
    state.projects = projects;
    state.noProjectsFound = projects.length === 0;
  },
  setOrderHistoryLines(state, { projectCode, orderHistoryLines, emptiedSearch }) {
    const sortedProjects = [...state.projects];

    var performingSearch = state.queryString.length > 0 || state.selectedProjectCode.length > 0;

    // If recently searched, and search request is emptied
    // pass it so displaying the set projects can be set to selectedProjectState 
    if (performingSearch || emptiedSearch) {
      sortedProjects.forEach(project => {
        project.OrderHistoryLines = null;
        project.Active = false;
      });  
    }    
    
    
    if (orderHistoryLines.length) {
      orderHistoryLines.forEach(orderLine => {
        let index = sortedProjects.findIndex(project => project.ProjectCode == orderLine.ProjectReference);
  
        if (index < 0) {
          index = sortedProjects.findIndex(project => project.ProjectCode == "OtherProjects");
        }
  
        if (sortedProjects[index].OrderHistoryLines === null) {
          sortedProjects[index].OrderHistoryLines = [];
        }
        sortedProjects[index].OrderHistoryLines.push(orderLine);
      });
    } else {
      let index = sortedProjects.findIndex(project => project.ProjectCode === projectCode);
      if (index > -1) {
        sortedProjects[index].OrderHistoryLines = [];
      }
    }

    // Set projects to false if they do not have order history
    // lines, but only if a search request is being performed 
    sortedProjects.forEach(project => {      
      
      if (performingSearch) {
        project.Active = project.OrderHistoryLines !== null;
      } else if (emptiedSearch) {
        project.Active = true;
      }
    });
    
    state.noResultsFound = state.projects.filter(orderLine => orderLine.OrderHistoryLines !== null).length === 0;    

    state.projects = sortedProjects;
  },
  setSelectedOrderHistoryDetails(state, payload) {
    state.selectedOrderHistoryDetails = payload;
  },
  setSelectedProjectCode(state, projectCode) {
    state.selectedProjectCode = projectCode;
    if (projectCode.length) {
      state.projects.forEach(project => {
        project.Active = project.ProjectCode === projectCode;
      });
    } else {
      state.projects.forEach(project => project.Active = true);
    }
  }
};

const actions = {
  initOrderHistory({ commit, dispatch }) {
    dispatch('getProjects');
  },
  getProjects({commit, dispatch}) {
    commit('setRetrievingProjects', true);
    const url = '/bespoke/ProjectListWebmethods.aspx/GetProjects';
    axios.post(url, {})
        .then(res => {         
          commit('setProjects', res.data.d);
          commit('setRetrievingProjects', false);
        }          
      );
  },
  getOrderHistory({ state, getters, commit }, { projectCode, queryString = '' }) {
    return new Promise((resolve, reject) => {
      
      const url = '/bespoke/OrderHistoryWebmethods.aspx/GetOrderHistory';      
      let selectedProjectCode = projectCode ? projectCode : getters.selectedProjectCode;
      
      let emptiedSearch = getters.queryString.length > 0 && queryString.length === 0;
      commit('setQueryString', queryString);

      axios.post(url, { projectCode: selectedProjectCode, queryString: queryString })
          .then(res => {
            commit('setOrderHistoryLines', { orderHistoryLines: res.data.d, emptiedSearch: emptiedSearch, projectCode: projectCode });
            resolve(true);
          }          
        );
    });
  },
  getOrderHistoryDetails({ commit }, { projectCode, orderId }) {
    
    const url = '/bespoke/OrderHistoryWebmethods.aspx/GetOrderHistoryDetails';
    
    commit('setSelectedOrderHistoryDetails', {});

    axios.post(url, { orderId: orderId })
        .then(res => {
          let orderHistoryDetails = {
            details: res.data.d.OrderHistoryDetails,
            orderLines: JSON.parse(res.data.d.OrderLines),
            projectCode: projectCode,
            orderId: orderId
          }
          commit('setSelectedOrderHistoryDetails', orderHistoryDetails);
        }          
      );
  },
  addRepeatOrderToCart({ commit, dispatch }, { projectName, orderId }) {
    return new Promise((resolve, reject) => {
      const url = '/bespoke/OrderHistoryWebmethods.aspx/GetOrderHistoryDetails';

      axios.post(url, { orderId: orderId })
          .then(res => {
            var orderLines = JSON.parse(res.data.d.OrderLines);
            
            var payload = [];
            orderLines.forEach(orderLine => {
              payload.push({
                  prod_code: orderLine.prod_code,
                  ord_qty: orderLine.ord_qty,
                  prod_comment: orderLine.prod_comment ? orderLine.prod_comment : "",
                  unit_code: orderLine.unit_code ? orderLine.unit_code : "",
                  customFields: {}
                });
            });

            dispatch('shoppingCart/emptyShoppingCartPromise', false, { root: true }).then(_ => {
              dispatch('shoppingCart/addToCart', {payload: payload, projectName: projectName }, {root:true}).then(res => {
                resolve();
              });
            });
            
            resolve(payload);
          }          
        );

    });
    
  },
  selectProjectCode({ commit }, event) {
    let projectCode = event.target.value;
    commit('setQueryString', '');
    commit('setNoProjectsFound', false);
    commit('setNoResultsFound', false);
    commit('setSelectedProjectCode', projectCode);
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
