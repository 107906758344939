<template>
  <div class="cat-info-tabs">
    <utlz-tabs :tabs="tabs">

      <template v-for="(tab, index) in tabs" v-slot:[tab.id]>
        <div :key="index" :class="tab.className">
          <div class="tab-content" v-html="tab.content">              
          </div>
        </div>
      </template>


      <!-- <template v-slot:proline>
        <div class="pro-line">
          <div class="certificate-banner">
            <div class="col-left">
              <img src="/Bespoke/Assets/Images/Vlint_PRO-line.png" alt="certificate-logo"/>
              <h3>{{ tabs[0].description }}</h3>              
            </div>
            <div class="col-right" v-html="textProLine">              
            </div>
          </div>
        </div>
      </template> -->
    </utlz-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Tabs from 'src/components/ui/tabs/Tabs.vue';
export default {
  components: {
    'utlz-tabs': Tabs
  },
  data() {
    return {
      tabs: []
    }   
  },
  computed: {
    ...mapState('productDetails', ['categoryInfoTabs']),
  },
  created() {
    if (this.categoryInfoTabs.length > 0) {
      
      var uniqueKey = 0;
      this.categoryInfoTabs.forEach(tabContent => {
        
        // const h3Match = tabContent.match(/<h3>.*?<\/h3>/);
        // const h3Match = tabContent.match(/<h3>(.*?)<\/h3>/);
        // const h3 = h3Match ? h3Match[0] : '';

        const h3Match = tabContent.match(/<h3>(.*?)<\/h3>/);
        const h3Text = h3Match ? h3Match[1] : ''; 

        // Extract the remaining part of the HTML
        const tabContentHtml = tabContent.replace(/<h3>.*?<\/h3>/, '');

        var id = "tab-" + uniqueKey;
        this.tabs.push({
          id: id,
          description: h3Text,
          active: uniqueKey == 0 ? true : false,
          visible: true,
          content: tabContentHtml
        });

        uniqueKey++;

      });
    }
  }
}
</script>

<style>

</style>