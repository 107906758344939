<template>
  <div class="cmp-edit-name">
      <div class="flex-row-nowrap" :class="{ 'editing': editing }">
        <input type="text"
          ref="valueField"
          :placeholder="placeholder"
          :value="defaultValue"
          @focus="edit()"
          @blur="updateComment()"
          @keyup.enter="$refs.valueField.blur()"
          maxlength="40"
          class="form-field text-theme-primary flex-sm-grow-1"
        />
        <a v-if="!editing" class="button-pencil" @click="edit()">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </a>
        <a v-else class="button square theme-primary">
          <i class="fa fa-check" aria-hidden="true"></i>
        </a>
        <transition name="fade">
          <span v-if="loading" class="loader"></span>
        </transition>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, required: true},
    valueKey: { type: String, required: true},
    defaultValue: { type: String, required: true},
    placeholder: { type: String, required: true }
  },
  data () {
    return {
      loading: false,
      editing: false
    }
  },
  methods: {
    edit () {
      this.editing = true;
      this.$refs.valueField.focus();
    },
    toggleEditing () {
      this.editing = !this.editing;
    },
    updateComment() {
      const nameValue = this.$refs.valueField.value;
      this.$emit('valueChanged', { id: this.id, valueKey: this.valueKey, value: nameValue });
      this.editing = false;
    }
    // TODO
    // Maybe do a check to see if the name is already taken?
    // Else just emit event straight to parent component
    // updateComment (orderLine) {
    //   const comment = this.$refs.orderLineComment.value;
    //   if (comment !== this.orderLine.comments) {
    //     this.loading = true;
    //     const payload = {
    //       Comments: comment,
    //       ProductId: this.orderLine.productId,
    //       LineId: this.orderLine.lineId,
    //       Quantity: this.orderLine.quantity,
    //       UnitCode: this.orderLine.unitCode,
    //       IsFree: this.orderLine.isFree
    //     };
    //     this.updateOrderLineComment(payload)
    //       .then(res => {
    //         this.loading = false;
    //       })
    //       .catch(
    //         err => {
    //           this.loading = false;
    //         });
    //   }
    //   this.$refs.orderLineComment.blur();
    //   this.editing = false;
    // }

  }
}
</script>

<style>

</style>
