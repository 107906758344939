<template>
  <div class="certificate-banners">
    <utlz-tabs :tabs="tabs">
      <template v-slot:proline>
        <div class="pro-line">
          <div class="certificate-banner">
            <div class="col-left">
              <img src="/Bespoke/Assets/Images/Vlint_PRO-line.png" alt="certificate-logo"/>
              <h3>{{ tabs[0].description }}</h3>              
            </div>
            <div class="col-right" v-html="textProLine">              
            </div>
          </div>
        </div>
      </template>
      <template v-slot:expertline>
        <div class="expert-line">
          <div class="certificate-banner">
            <div class="col-left">
              <img src="/Bespoke/Assets/Images/Vlint_EXPERT-line.png" alt="certificate-logo"/>
              <h3>{{ tabs[1].description }}</h3>              
            </div>
            <div class="col-right" v-html="textExpertLine">              
            </div>
          </div>
        </div>
      </template>
      <template v-slot:energyline>
        <div class="pro-line">
          <div class="certificate-banner">
            <div class="col-left">
              <img src="/Bespoke/Assets/Images/Vlint_ENERGY-line.png" alt="certificate-logo"/>
              <h3>{{ tabs[2].description }}</h3>              
            </div>
            <div class="col-right" v-html="textEnergyLine">              
            </div>
          </div>
        </div>
      </template>
    </utlz-tabs>
  </div>
</template>

<script>
import Tabs from 'src/components/ui/tabs/Tabs.vue';
export default {
  components: {
    'utlz-tabs': Tabs
  },
  data() {
    return {
      textProLine: window.vue.translations.text.text_tab_proline,
      textExpertLine: window.vue.translations.text.text_tab_expertline,
      textEnergyLine: window.vue.translations.text.text_tab_energyline,
      tabs: [
        { id: 'proline', description: window.vue.translations.label.label_tab_proline, active: true, visible: true },
        { id: 'expertline', description: window.vue.translations.label.label_tab_expertline, active: false, visible: true },
        { id: 'energyline', description: window.vue.translations.label.label_tab_energyline, active: false, visible: true }
      ]
    }   
  }
}
</script>

<style>

</style>