import axios from 'axios';
import { Product } from 'src/models/product.model';
import { isRepresentativeProduct } from '../services/product-service';

const state = {
  projects: [],
  productsPerProject: []
};

const getters = {
  projects(state) {
    return state.projects;
  },
  productsPerProject(state) {
    return state.productsPerProject;
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}?language=${language}`;
  }
};

const mutations = {
  setProjects(state, payload) {
    var projects = payload;
    projects.forEach(project => {
      project.Locations.sort((x, y) => x.RowOrder - y.RowOrder);
      project.Locations.forEach(location => {
        location.Products.sort((x, y) => x.RowOrder - y.RowOrder);
        location.Products.forEach(x => x.isRepresentativeProduct = isRepresentativeProduct(x.Id));
      });
    });
    state.projects = projects;
  },
  setProductPrice(state, payload) {
    let projCode = payload.projectCode;
    let product = payload.product;
    let projectCopy = [...state.projects];
    projectCopy.forEach(y => {
      if (y.ProjectCode === projCode) {
        y.productInformation.filter((x) => x.id === product.id)[0] = product;
      }
    }); 
    state.projects = projectCopy;
  },
  setProjectWithPrices(state, payload) {
    let projectCopy = [...state.projects];

    projectCopy.filter((x) => x.ProjectCode === payload.projectCode)[0].productInformation = payload.products;

    state.projects = projectCopy;
  }
};

const actions = {
  initProjectList({commit, dispatch}) {
    dispatch('getProjects');
  },
  getProjects({commit, dispatch}) {
    return new Promise(resolve => {
    const url = '/bespoke/ProjectListWebmethods.aspx/GetProjectsWithLocations';
    axios.post(url, {})
        .then(res => {
          commit('setProjects', res.data.d);
          resolve(res.data.d);
        }          
      );
    });
  },
  createNewCart({commit, dispatch}) {
    const url = '/bespoke/ProjectListWebmethods.aspx/saveCartAsConceptOrder';
    axios.post(url, {})
        .then(res => {
          location.reload();
        }          
      );
  },
  deleteProductFromDb({commit, dispatch}, productLineId) {
    const url = '/bespoke/ProjectListWebmethods.aspx/deleteProjectProduct';
    axios.post(url, {productLineId: productLineId})
        .then(res => {
        }          
      );
  },
  deleteProjectFromDb({commit, dispatch}, projCode) {
    const url = '/bespoke/ProjectListWebmethods.aspx/deleteProject';
    axios.post(url, {projectCode: projCode})
        .then(res => {
        }          
      );
  },
  getProductInformation({ commit, getters, dispatch, rootGetters }, project) {
    if (!getters.productsPerProject.filter(x => x.projectCOde = project.ProjectCode).length) {
      let mainProductCodes = [];
      project.Locations.forEach((x)=> x.Products.forEach((p) => mainProductCodes.push(p.Id)));
      mainProductCodes = [...new Set(mainProductCodes)];
      axios.post(getters.productInformationEndpoint, mainProductCodes)
        .then(res => {
          const products = res.data.map(product => new Product(product));

          products.forEach(product => product.isRepresentativeProduct = isRepresentativeProduct(product.id));

          let projectWithProducts = {
            projectCode: project.ProjectCode,
            products: products
          }
          commit('setProjectWithPrices', projectWithProducts);

          dispatch('getProductPrices', projectWithProducts);
        }
      );
    }    
  },
  getProductPrices ({ commit, rootGetters, dispatch }, projectWithProducts) {
    let products = projectWithProducts.products;
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

      // Added productsfetched to count if all products have been returned
      let productsFetched = 0;

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);         
          commit('setProductPrice', {projectCode: projectWithProducts.projectCode, product: product});
        });
      });
  },
  saveProject() {
    
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
