<template>
  <div class="cmp-order-history row" :ref="'history-overview'">
    
    <transition name="fadein">
      <div class="cmp-prompt" v-if="showRepeatOrderPrompt">
          <div class="full-screen-fader flex-column justify-content-center">
              <div class="utlz-container">
              <div class="utlz-row">
                  <div class="utlz-col-lg-8 utlz-offset-lg-2">
                  <span class="close-button">
                      <i class="fa fa-times-circle" aria-hidden="true" @click="hidePrompt()"></i>
                  </span>
                  <div class="prompt-content">
                      <h2 v-translation="{ code: 'title_repeat_ord', type: 'title' }">
                          
                      </h2>
                      <div>
                          <span v-translation="{type: 'text', code: 'text_prompt_repeat_ord'}"></span>
                      </div>
                      <div class="action-btns full-width-buttons-mobile">
                          <a @click="showRepeatOrderPrompt = false" v-translation="{ type: 'button', code: 'button_cancel_repeat_ord'}" class="button cancel"></a>
                          <a @click="confirmRepeatOrder()" v-translation="{ type: 'button', code: 'button_confirm_repeat_ord'}" class="button theme-primary confirm"></a>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </div>
  </transition>


    <template v-if="retrievingProjects">
      <div class="loader-wrapper">
        <span class="loader"></span>  
        <span v-translation="{ type: 'label', code: 'label_loading_data' }"></span>
      </div>  
    </template> 

    <div v-if="projects.length && !showingOrderDetails" class="filters col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="select-wrapper">
            <span class="fa fa-angle-down select-angle"></span>
            <select @change="changeProjectCode($event)" v-model="selectedProjectCode">
              <option value="">{{ selectProjectPlaceholder }}</option>
              <option :value="project.ProjectCode" v-for="project in projects" :key="project.ProjectCode">{{ project.ProjectDescription }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="search-wrapper">
            <template v-if="loadingSearchRequest">              
              <span class="loader"></span>
            </template>
            <template v-else>
              <i class="fa fa-search" aria-hidden="true"></i>
            </template>
            <input type="text" v-model="queryString" @input="searchOrderLines" :placeholder="searchOrderNrPh"/>
          </div>
        </div>
      </div>
      
    </div>

    <div class="projects-wrapper col-md-12" :class="{ 'hide-projects': showingOrderDetails }">
      <div class="project-history" v-if="projects.length">
        <div class="project-line" v-for="project in projects" :key="project.ProjectCode">
          <div v-if="project.Active"  class="project-line-header" :ref="'project-header-' + project.ProjectCode" 
            @click="toggleProject(project.ProjectCode)">
            <span class="fa fa-angle-down"></span>
            <h2 class="project-desc">
              {{ project.ProjectDescription }}
            </h2>
          </div>
          
          <div v-if="project.Active" class="project-line-body" :ref="'project-body-' + project.ProjectCode">
            
            <template v-if="project.OrderHistoryLines !== null">
              <template v-if="project.OrderHistoryLines.length">
                <div v-for="historyLine in project.OrderHistoryLines"
                     :key="historyLine.OrderId" class="order-history-line"
                     @click="showOrderDetails({ projectCode: project.ProjectCode, orderId: historyLine.OrderId })">
                  <div class="order-id">
                    {{ historyLine.OrderId }}
                  </div>
                  <div class="order-desc">
                    {{ historyLine.OrderDescription }}
                  </div>
                  <div class="order-date">
                    {{ historyLine.OrderDate }}
                  </div>
                </div>  
              </template>
              <template v-else>
                 <p v-translation="{ code: 'label_no_results_found', type: 'label' }"></p>
              </template>
            </template>     

            <template v-else>
              <div class="loader-wrapper">
                <span class="loader"></span>  
                <span v-translation="{ type: 'label', code: 'label_loading_data' }"></span>
              </div>  
            </template>   

          </div>
          
        </div>
        
      </div>
      <div v-if="noResultsFound || noProjectsFound" class="loading-wrapper">
        <h2 v-translation="{ code: 'label_no_results_found', type: 'label' }"></h2>
      </div>
    </div>

    <div class="order-details-wrapper col-md-12" :class="{ 'hide-details': !showingOrderDetails }">
      <template v-if="Object.keys(selectedOrderHistoryDetails).length">        
        
        <div class="return-btn-row">
          <a @click="closeDetails()">
            <i class="fa fa-angle-left"></i>
            <span v-translation="{ type: 'label', code: 'label_back_tolist'}"></span>
          </a>
        </div>

        <h3>Order details</h3>
        <div class="line-divider">
          <hr/>
        </div>
        
        <div class="order-summary row">
          <div class="row-inner">
            <div class="order-nr col-md-6">
              <h3>
                <span v-translation="{ code: 'title_order_nr', type: 'title' }"></span>
                {{ selectedOrderHistoryDetails.details.documentNumber }}
              </h3>
              <span>{{selectedOrderHistoryDetails.details.orderDate }}</span>
            </div>
            <div class="order-amt col-md-6">
                 <h3>
                <span v-translation="{ code: 'title_order_amt', type: 'title' }"></span>
                {{ selectedOrderHistoryDetails.details.orderTotalInclVat }}</h3>
            </div>
          </div>
        </div>

        <div class="order-line-body row">      
          <div class="order-line-header order-line-row">
            <div class="prod-code">
            </div>
            <div class="prod-desc">
               <span v-translation="{ type: 'label', code: 'label_prod_desc'}"></span>
            </div>
            <div class="order-quantity">
              <span v-translation="{ type: 'label', code: 'label_amount'}"></span>
            </div>
            <div class="order-amount">
              <span v-translation="{ type: 'label', code: 'label_row_amt'}"></span>
            </div>
          </div>

          <div v-for="orderLine in selectedOrderHistoryDetails.orderLines" :key="orderLine.crec_id" class="order-line-row">
            <div class="prod-code">
              {{ orderLine.prod_code }}
            </div>
            <div class="prod-desc">
              {{ orderLine.prod_desc }}
            </div>
            <div class="order-quantity">
              <span class="mobile-label" v-translation="{ type: 'label', code: 'label_amount'}"></span>
              {{ orderLine.ord_qty }}
            </div>
            <div class="order-amount">
              <span class="mobile-label" v-translation="{ type: 'label', code: 'label_row_amt'}"></span>
              {{ orderLine.row_amt }}
            </div>
          </div>
        </div>        
      </template>

      <template v-else>
        <div class="loader-wrapper">
          <span class="loader"></span>  
          <span v-translation="{ type: 'label', code: 'label_loading_data' }"></span>
        </div>
      </template>

      <div class="return-btn-row">
          <a @click="closeDetails()">
            <i class="fa fa-angle-left"></i>
            <span v-translation="{ type: 'label', code: 'label_back_tolist'}"></span>
          </a>
          
          <div class="repeat-order">
            <span @click="repeatOrder($event)" class="btn-u btn-grey text-link" v-translation="{code: 'link_copy_history_order', type: 'button' }"></span>
          </div>
        </div>
    </div>

    
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      showingOrderDetails: false,
      queryString: "",
      selectedProjectCode: "",
      selectProjectPlaceholder: window.vue.translations.label.label_select_project,
      loadingSearchRequest: false,
      showRepeatOrderPrompt: false,
      repeatOrderPayload: null,
      repeatOrderDetails: {},
      searchOrderNrPh: window.vue.translations.label.label_search_ordnumber
    };
  },
  computed: {
    ...mapGetters('orderHistory', ['projects', 'selectedOrderHistoryDetails', 'noResultsFound', 'noProjectsFound', 'retrievingProjects']),
  },
  methods: {
    ...mapActions('orderHistory', ['getOrderHistory', 'getOrderHistoryDetails', 'selectProjectCode', 'searchOrderHistory', 'addRepeatOrderToCart']),
    toggleProject(projectCode) {
      this.$refs['project-header-' + projectCode][0].classList.toggle('active');
      this.$refs['project-body-' + projectCode][0].classList.toggle('active');
      
      let project = this.projects.filter(project => project.ProjectCode === projectCode)[0];
      
      if (project.OrderHistoryLines === null) {
        this.getOrderHistory({ projectCode: projectCode });
      }
    },
    showOrderDetails(orderDetails) {
      this.showingOrderDetails = !this.showingOrderDetails;
      $('.account-page-desc').addClass('showing-details');
      this.getOrderHistoryDetails(orderDetails);
      window.scrollTo(0, 0);
    },
    closeDetails() {
      this.showingOrderDetails = false;
      $('.account-page-desc').removeClass('showing-details');
    },
    changeProjectCode(event) {
      this.queryString = '';
      $('.cmp-order-history').removeClass('searching');
      this.selectProjectCode(event);
    },
    searchOrderLines() {
      let payload = {
        queryString: this.queryString
      };
      this.loadingSearchRequest = true;
      this.getOrderHistory(payload).then(_ => {
        this.loadingSearchRequest = false;
      });
      if (this.queryString.length) {
        $('.cmp-order-history').addClass('searching');
      } else {
        $('.cmp-order-history').removeClass('searching');
      }
    },
    repeatOrder(event) {
      event.stopPropagation();
      this.showRepeatOrderPrompt = true;
      this.repeatOrderDetails = {
        projectName: this.selectedOrderHistoryDetails.projectCode,
        orderId: this.selectedOrderHistoryDetails.orderId
      };
    },
    confirmRepeatOrder() {      
      this.addRepeatOrderToCart(this.repeatOrderDetails).then(historyDetailsPayload => {
        this.showRepeatOrderPrompt = false;
      });
    }
  },
  created() {
    this.searchOrderLines = _.debounce(this.searchOrderLines, 750);
  }
}
</script>
