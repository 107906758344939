<template>
<transition name="fade">
  <div v-if="showAddedToCartMessage" class="shopping-cart-message">
    <span class="close-icon" @click="closeMessage()"></span>
    <div class="panel-header">
      <div class="cart-icon">
        <img src="/Bespoke/Assets/Images/shopping_cart_icon.svg" class="cart-icon" alt="shopping cart icon"/>
        <span class="total-counter btn-u">{{ totalProductsCount }}</span>
      </div>
      <p v-translation="{ type: 'label', code: 'label_product_added' }"></p>
    </div>
    <div class="actions">
      <a :href="shoppingCartUrl" class="btn-u" v-translation="{ type: 'button', code: 'button_view_shopping_cart' }"></a>
      <template v-if="userLoggedOn">
        <a :href="projectListUrl" class="link-grey" v-translation="{ type: 'label', code: 'label_view_projects' }"></a>
      </template>
    </div>
  </div>
</transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    shoppingCartUrl: { type: String, required: true}
  },
  computed: {    
    ...mapGetters(['language', 'userLoggedOn']),
    ...mapGetters('shoppingCart', ['showAddedToCartMessage', 'totalProductsCount']),
    projectListUrl() {
      return `/${this.language}/bespoke/projectlist.aspx`;
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['setShowAddedToCartMessage']),
    closeMessage() {
      this.setShowAddedToCartMessage(false);
    }
  }
}
</script>

<style>

</style>