<template>
  <div class="cmp-searchbar">
    <div class="form-field">
      <input type="text"
             :placeholder="placeholder"
             @keyup="searchString($event.target.value)"
             @keydown.enter="updateByEnter($event.target.value)"
             :value="value" />
      <i class="fa fa-times-circle" v-if="Object.keys(selectedProduct).length" aria-hidden="true" @click="emptySelectedProduct"></i>
    </div>
    <div class="autocomplete-data" v-if="productsFound">
      <div v-for="(option, index) in autocompleteData" :key="index" class="auto-complete-option">
        <div v-on:click="updateFromList(option.key)">
          <div class="option-key">
            {{option.key}}
          </div>
          <div>
            <h2 class="option-name">{{ option.value }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="autocomplete-data no-products-found" v-else-if="!productsFound && autocompleteData.length">
      <span v-translation="{ code: 'label_no_products_found', type: 'label' }"></span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

// BESPOKE VLINT
import _ from 'lodash';
// END BESPOKE VLINT

export default {
  props: {
    placeholder: { type: String, required: false },
    value: ''
  },
  computed: {
    ...mapState('orderEntry', ['productsFound', 'autocompleteData', 'selectedProduct'])
  },
  methods: {
    ...mapActions('orderEntry', ['getAutocompleteData', 'updateSearchText', 'getProductInformation', 'clearSelectedProduct', 'loadRepresentativeProducts']),
    searchString (textField) {
      this.updateSearchText(textField);
      this.getAutocompleteData();
      this.$emit('input', textField);
    },
    updateByEnter (textField) {
      if (this.productsFound) {
        this.getProductInformation();
        this.$emit('input', textField);
      }
    },
    updateFromList (id) {
      this.updateSearchText(id);
      this.$emit('input', id);
      this.getProductInformation();
    },
    emptySelectedProduct () {
      this.textField = '';
      this.clearSelectedProduct();
    }
  },
  // BESPOKE VLINT
  // Added debounce so that user input is throttled by 750 ms before firing a new ajax request
  created() {
    this.searchString = _.debounce(this.searchString, 750);
    this.loadRepresentativeProducts();
  }
  // END BESPOKE VLINT
}
</script>

<style>
</style>
