<template>
  <div class="cmp-search-product rows utlz-reset">

    <h2 v-translation="{ type:'title', code: 'title_add_prodtoloc' }"></h2>
    
    <utlz-searchbar id="searchbar" :placeholder="placeholder" v-model="searchText" />

    <transition name="fade">
      <div class="entry-product" v-if="selectedProduct.prices !== undefined">
        <div class="prod-img">
          <img :src="selectedProduct.imageUrl ? selectedProduct.imageUrl : '/documents/productimages/not-available-small.jpg'" />
        </div>
        <div class="prod-desc">
          <p>{{ selectedProduct.shortDescription }}</p>          
          <p class="prod-code">{{ selectedProduct.id }}</p>
        </div>
        <div class="prod-price" v-if="selectedProduct.prices.length">
          <utlz-product-price            
            :prices="selectedProduct.prices[0]"
            ></utlz-product-price>
        </div>
        <div class="quantity">
          <utlz-quantity-buttons
              :initialQuantity="1"
              :allowZeroQuantity="false"
              :quantityFactor="1"
              @quantityChanged="quantityChanged($event)">
          </utlz-quantity-buttons>
        </div>
        <div class="add-product-btn">
          <a @click="addProductToLocation(selectedProduct.id)" v-translation="{ code: 'button_add_prod', type: 'button'}" class="btn-u blue-btn"></a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import Searchbar from './../search-bar/Searchbar.vue';
  import ProductListItem from './../../../../src/components/webshop/products/product-list-item/ProductListItem.vue';
  import ProductPrice from './../../../../src/components/webshop/product-price/ProductPrice.vue'
  import QuantityButtons from './../../../../src/components/webshop/order-product/blocks/QuantityButtons.vue';
  export default {
    data() {
      return {
        placeholder: window.vue.translations.label.label_ph_search_prod,
        searchText: '',
        setQuantity: 1
      }
    },
    methods: {
      ...mapActions('orderEntry', ['clearSelectedProduct']),
      addProductToLocation() {
        const locationProductGuid = 'TEMP' + crypto.randomUUID();
        const locationProduct = {
          DbId: locationProductGuid,
          Description: this.selectedProduct.shortDescription,
          Id: this.selectedProduct.id,
          ImageUrl: this.selectedProduct.imageUrl ? this.selectedProduct.imageUrl : '/documents/productimages/not-available-small.jpg',
          Quantity: this.setQuantity
        };
        this.searchText = '';
        this.setQuantity = 1;
        this.clearSelectedProduct();
        this.$emit('addProductToLocation', locationProduct);
      },
      quantityChanged(ev) {
        this.setQuantity = ev.quantity;
      }
    },
    components: {
      'utlz-searchbar': Searchbar,
      'utlz-product-list-item': ProductListItem,
      'utlz-product-price': ProductPrice,
      'utlz-quantity-buttons': QuantityButtons
    },
    computed: {
      ...mapState('orderEntry', ['selectedProduct'])
    }
  }
</script>

<style>
</style>
